document.addEventListener('DOMContentLoaded', () => {
  const desktopParentLink = document.querySelectorAll('#menu-primary .menu-item-has-children > a')
  const mobileParentLink = document.querySelectorAll('#mobile-menu .menu-item-has-children ')
  const arrowMenu = document.querySelectorAll('.menu-toggle')

  mobileParentLink.forEach(function (link) {
    const svgPlus = link.querySelector('.svg-plus')
    const svgMinus = link.querySelector('.svg-minus')

    if (svgPlus) {
      svgPlus.addEventListener('click', function (event) {
        event.stopPropagation()
        link.click()
      })
    }

    if (svgMinus) {
      svgMinus.addEventListener('click', function (event) {
        event.stopPropagation()
        link.click()
      })
    }

    link.addEventListener('click', function (event) {
      event.preventDefault()
      event.stopPropagation()

      const megaMenu = document.querySelector('.mega-menu-mobile')
      const plusIcon = document.querySelector('.svg-plus')
      const minusIcon = document.querySelector('.svg-minus')

      if (megaMenu) {
        link.parentNode.insertBefore(megaMenu, link.nextSibling)

        if (megaMenu.classList.contains('sub-menu--open')) {
          megaMenu.classList.remove('sub-menu--open')

          if (plusIcon && minusIcon) {
            plusIcon.style.display = 'block'
            minusIcon.style.display = 'none'
          }
        } else {
          megaMenu.classList.add('sub-menu--open')

          if (plusIcon && minusIcon) {
            plusIcon.style.display = 'none'
            minusIcon.style.display = 'block'
          }
        }
      }
    })
  })

  function menuToggle() {
    desktopParentLink.forEach(function (link) {
      const svg = link.nextElementSibling

      function toggleMenu(event) {
        event.preventDefault()
        event.stopPropagation()
        var megaMenu = document.querySelector('.mega-menu')
        if (megaMenu) {
          if (megaMenu.classList.contains('sub-menu--open')) {
            megaMenu.classList.remove('sub-menu--open')
            svg.classList.remove('rotate')
          } else {
            megaMenu.classList.add('sub-menu--open')
            svg.classList.add('rotate')
          }
        }
      }

      link.addEventListener('click', toggleMenu)
      if (svg) {
        svg.addEventListener('click', toggleMenu)
      }
    })
  }
  menuToggle()

  const menuButton = document.querySelector('.menu-button')
  const body = document.body

  menuButton.addEventListener('click', function () {
    if (body.classList.contains('menu-open')) {
      body.classList.remove('menu-open')
    } else {
      body.classList.add('menu-open')
    }
  })
})
