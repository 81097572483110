/* global initialPrice, utforandeBreddValues, heightPrice, breddPrice, variationPrices, updatePrice, calculationType, totalHeightPrice, transomPrice , utforandeData*/

document.addEventListener('DOMContentLoaded', function () {
  const productVariable = document.querySelector('.product-type-variable')
  if (!productVariable) {
    return
  }

  var priceElement = document.querySelector('.woocommerce-Price-amount')

  function updateInitialPrice() {
    const variationPriceContainer = document.querySelector('.woocommerce-variation-price')
    if (variationPriceContainer) {
      // Only update with base price initially
      const basePrice = initialPrice * 1.25 // Add tax
      const priceHtml = `
        <span class="price">
          <span class="woocommerce-Price-amount amount">
            ${basePrice.toLocaleString('sv-SE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} kr
          </span>
          <small class="woocommerce-price-suffix"> inkl. moms</small>
        </span>`

      variationPriceContainer.innerHTML = priceHtml
      console.log('Initial price set to:', basePrice)
      priceElement = variationPriceContainer.querySelector('.woocommerce-Price-amount')
    }
  }

  function updateUtforandePrice(selectedValue) {
    if (utforandeData[selectedValue]) {
      return parseFloat(utforandeData[selectedValue].price) || 0
    }
    return 0 // Default price if no data is found
  }

  function getSelectedUtforande() {
    const selectedUtforande = document.querySelector('.variable-items-wrapper li.selected')
    if (selectedUtforande) {
      return selectedUtforande.getAttribute('data-value')
    }
    return null
  }

  function getVariationPrice(variationId) {
    return parseFloat(variationPrices[variationId]) || 0
  }

  function updateBreddPlaceholder(breddInput, breddValues) {
    breddInput.placeholder = 'Minst ' + breddValues.min + 'mm, Max ' + breddValues.max + 'mm'
  }

  function validateBreddInput(breddInput, breddValues) {
    var bredd = parseInt(breddInput.value) || parseInt(breddValues.min)
    var errorMessage = document.getElementById('bredd-error-message')
    var isValid = true

    // Remove existing error states
    breddInput.classList.remove('border-red-500')
    if (errorMessage) {
      errorMessage.remove()
    }

    // Create error message element if needed
    function createErrorMessage() {
      errorMessage = document.createElement('div')
      errorMessage.id = 'bredd-error-message'
      errorMessage.className = 'mt-1 text-xs text-red-500'
      breddInput.parentNode.appendChild(errorMessage)
      return errorMessage
    }

    // Validate input
    if (!breddInput.value) {
      isValid = false
      breddInput.classList.add('border-red-500')
      errorMessage = errorMessage || createErrorMessage()
      errorMessage.textContent = 'Bredden måste fyllas i.'
    } else if (bredd < breddValues.min || bredd > breddValues.max) {
      isValid = false
      breddInput.classList.add('border-red-500')
      errorMessage = errorMessage || createErrorMessage()
      errorMessage.textContent =
        'Bredden måste vara mellan ' + breddValues.min + 'mm och ' + breddValues.max + 'mm.'
    }

    // Store validation state
    breddInput.setAttribute('data-valid', isValid)
    return isValid
  }
  // Define updatePrice function globally
  window.updatePrice = function () {
    priceElement = document.querySelector('.woocommerce-variation-price .woocommerce-Price-amount')
    if (!priceElement) {
      priceElement = document.querySelector('.woocommerce-Price-amount')
    }

    // Get variation price
    var variationId = document.querySelector('.variation_id').value
    var variationPrice = getVariationPrice(variationId)
    console.log('Variation Price:', variationPrice)

    // Get utforande price
    var utforandePrice = 0
    const selectedValue = getSelectedUtforande()
    if (selectedValue) {
      var breddValues = utforandeBreddValues[selectedValue] || { min: 0, max: 0 }
      var breddInput = document.getElementById('tillagg_bredd')
      updateBreddPlaceholder(breddInput, breddValues)
      validateBreddInput(breddInput, breddValues)
      utforandePrice = updateUtforandePrice(selectedValue)
    }
    console.log('Utforande Price:', utforandePrice)

    // Get dimensions
    var bredd = parseInt(document.getElementById('tillagg_bredd').value) || 0
    var hojd = parseInt(document.getElementById('tillagg_hojd').value) || 0
    var totalHeight = parseInt(document.getElementById('total_height_field').value) || 0

    // Add check for transom_height_field
    const transomField = document.getElementById('transom_height_field')
    var overljus = transomField ? parseInt(transomField.value) || 0 : 0

    var opening =
      calculationType !== 'total_height'
        ? parseInt(document.getElementById('opening_height_field').value) || 0
        : 0

    var totalPrice = variationPrice + utforandePrice
    if (bredd > 0 || hojd > 0 || totalHeight > 0 || opening > 0) {
      if (calculationType === 'total_height' && bredd > 0) {
        totalPrice += breddPrice * bredd + heightPrice * hojd
      } else if (calculationType === 'opening_height' && bredd > 0) {
        totalPrice += breddPrice * bredd + transomPrice * overljus + heightPrice * hojd
      } else if (calculationType === 'opening_height_split' && bredd > 0) {
        totalPrice += breddPrice * bredd + totalHeightPrice * totalHeight
      }
    }
    console.log(calculationType)

    if (!priceElement) {
      const variationPriceContainer = document.querySelector('.woocommerce-variation-price')
      if (variationPriceContainer) {
        const priceHtml = `
          <span class="price">
            <span class="woocommerce-Price-amount amount"></span>
            <small class="woocommerce-price-suffix"> inkl. moms</small>
          </span>`
        variationPriceContainer.innerHTML = priceHtml
        priceElement = variationPriceContainer.querySelector('.woocommerce-Price-amount')
      } else {
        console.error('Price container is not found.')
        return
      }
    }

    const finalPriceWithTax = totalPrice * 1.25

    // Update displayed price
    priceElement.innerHTML = finalPriceWithTax.toLocaleString('sv-SE') + ' kr'

    // Update hidden input field with price including tax
    const customPriceInput = document.getElementById('custom_price')
    if (!customPriceInput) {
      // Create hidden input if it doesn't exist
      const input = document.createElement('input')
      input.type = 'hidden'
      input.id = 'custom_price'
      input.name = 'custom_price'
      document.querySelector('form.cart').appendChild(input)
    }
    document.getElementById('custom_price').value = finalPriceWithTax.toFixed(2)
  }

  function updateTotalHeight() {
    var height = parseFloat(document.getElementById('tillagg_hojd').value) || 0
    var totalHeight = height * 2 - 300
    document.getElementById('total_height_field').value = totalHeight + ' mm'

    // Update the hidden input field for total height
    var totalHeightHiddenField = document.getElementById('total_height')
    if (totalHeightHiddenField) {
      totalHeightHiddenField.value = totalHeight
    }
    updatePrice() // Ensure price is updated when total height changes
  }

  function updateHeights() {
    var h1 = parseFloat(document.getElementById('tillagg_hojd').value)
    var h3 = parseFloat(document.getElementById('total_height_field').value)

    if (isNaN(h3)) {
      return // Exit the function if totalHeightField is empty or not a number
    }
    var x = h1 - 315
    var y = h3 - h1 - x
    document.getElementById('opening_height_field').value = x + ' mm'
    document.getElementById('transom_height_field').value = y + ' mm'

    // Update hidden input fields
    document.getElementById('opening_height').value = x
    document.getElementById('transom_height').value = y
    document.getElementById('total_height').value = h3

    updatePrice() // Ensure price is updated when heights change
  }

  function updateSplitHeights() {
    var h1 = parseFloat(document.getElementById('tillagg_hojd').value)
    var h3 = parseFloat(document.getElementById('total_height_field').value)

    if (isNaN(h1) || isNaN(h3)) {
      return // Exit if h1 or h3 is not a number
    }

    // Calculate opening height (x)
    var x = Math.floor(((h3 - h1 - 51) * 2) / 3)

    // Calculate transom height (y)
    var y = h3 - h1 - x

    // Update the fields
    document.getElementById('opening_height_field').value = x + ' mm'
    document.getElementById('transom_height_field').value = y + ' mm'
    // Update hidden input fields
    document.getElementById('opening_height').value = x
    document.getElementById('transom_height').value = y
    document.getElementById('total_height').value = h3
    updatePrice() // Ensure price is updated when split heights change
  }

  updateInitialPrice()

  var utforandeDropdown = document.querySelector('select[name="attribute_pa_utforande"]')
  if (utforandeDropdown) {
    utforandeDropdown.addEventListener('change', function () {
      updatePrice()
      var breddInput = document.getElementById('tillagg_bredd')
      var utforande = utforandeDropdown.value
      var breddValues = utforandeBreddValues[utforande] || { min: 0, max: 0 }
      updateBreddPlaceholder(breddInput, breddValues)
    })
  }

  const utforandeContainer = document.querySelector(
    '.variable-items-wrapper[data-attribute_name="attribute_pa_utforande"]'
  )
  if (utforandeContainer) {
    utforandeContainer.addEventListener('click', function (event) {
      const selectedUtforande = event.target.closest('li')
      if (selectedUtforande) {
        setTimeout(updatePrice, 0)
      }
    })
  }

  // Calculation for total height, opening height, and transom height
  var tillaggHojd = document.getElementById('tillagg_hojd')
  var totalHeightField = document.getElementById('total_height_field')
  var openingHeightField = document.getElementById('opening_height_field')
  var transomHeightField = document.getElementById('transom_height_field')

  function updatePlaceholderAndValidate() {
    const selectedTerm = tillaggHojd.options[tillaggHojd.selectedIndex]
    const min = parseFloat(selectedTerm.getAttribute('data-min'))
    const max = parseFloat(selectedTerm.getAttribute('data-max'))
    totalHeightField.placeholder = `Min: ${min} mm, Max: ${max} mm`
    totalHeightField.min = min
    totalHeightField.max = max

    // Validate the value of totalHeightField
    const totalHeightValue = parseFloat(totalHeightField.value)
    let errorMessage = document.getElementById('height-error-message')

    // Reset the input and error state
    totalHeightField.classList.remove('border-red-500')
    if (errorMessage) {
      errorMessage.remove()
    }
    // Check if the value is within the min and max range
    if (isNaN(totalHeightValue) || totalHeightValue < min || totalHeightValue > max) {
      // Add red border to the input
      totalHeightField.classList.add('border-red-500')

      // Create or update the error message
      if (!errorMessage && calculationType !== 'total_height') {
        errorMessage = document.createElement('div')
        errorMessage.id = 'height-error-message'
        errorMessage.className = 'mt-1 text-xs text-red-500'
        totalHeightField.parentNode.appendChild(errorMessage)
      }
      errorMessage.textContent = 'Höjden måste vara mellan ' + min + ' mm och ' + max + ' mm.'

      // Ensure the error message is appended to the correct parent element
      if (!totalHeightField.parentNode.contains(errorMessage)) {
        totalHeightField.parentNode.appendChild(errorMessage)
      }

      // Don't update the price if the input is invalid
      return false
    }

    return true
  }

  // Update min and max values
  function setInitialMinMax() {
    const selectedTerm = tillaggHojd.options[tillaggHojd.selectedIndex]
    const min = parseFloat(selectedTerm.getAttribute('data-min'))
    const max = parseFloat(selectedTerm.getAttribute('data-max'))
    totalHeightField.min = min
    totalHeightField.max = max
    totalHeightField.placeholder = `Min: ${min} mm, Max: ${max} mm`
  }

  setInitialMinMax()

  tillaggHojd.addEventListener('change', function () {
    setInitialMinMax()
    updatePlaceholderAndValidate()
    updatePrice()
  })

  // Automatically update heights when input changes
  tillaggHojd.addEventListener('input', function () {
    if (calculationType === 'total_height') {
      updateTotalHeight()
    } else if (calculationType === 'opening_height') {
      updateHeights()
    } else if (calculationType === 'opening_height_split') {
      updateSplitHeights()
    }
    updatePlaceholderAndValidate()
    updatePrice()
  })

  totalHeightField.addEventListener('input', function () {
    if (calculationType === 'opening_height') {
      updateHeights()
    } else if (calculationType === 'opening_height_split') {
      updateSplitHeights()
    }
    updatePlaceholderAndValidate()
    updatePrice() // Ensure price is updated when total height input changes
  })

  // Initial calculations
  if (calculationType === 'total_height') {
    updateTotalHeight()
  } else if (calculationType === 'opening_height') {
    updateHeights()
  } else if (calculationType === 'opening_height_split') {
    updateSplitHeights()
  }

  const addToCartButton = document.querySelector('.single_add_to_cart_button')
  const requiredFields = [
    document.getElementById('tillagg_bredd'),
    document.getElementById('tillagg_hojd'),
    document.getElementById('total_height_field'),
    document.getElementById('opening_height_field'),
    document.getElementById('transom_height_field')
  ]

  // Create the message element
  const messageElement = document.createElement('div')
  messageElement.textContent = 'Alla fält måste fyllas innan du lägger till i varukorgen.'

  messageElement.style.color = 'red'
  messageElement.style.marginBottom = '10px'
  messageElement.style.display = 'none' // Hide the message initially
  addToCartButton.parentNode.insertBefore(messageElement, addToCartButton)

  addToCartButton.addEventListener('click', function (event) {
    const breddInput = document.getElementById('tillagg_bredd')
    const selectedValue = getSelectedUtforande()
    const breddValues = utforandeBreddValues[selectedValue] || { min: 0, max: 0 }

    // Validate width input
    const isBreddValid = validateBreddInput(breddInput, breddValues)
    let allFieldsFilled = true

    requiredFields.forEach((field) => {
      if (!field.value) {
        allFieldsFilled = false
        field.classList.add('border-red-500')
      } else {
        field.classList.remove('border-red-500')
      }
    })

    if (!allFieldsFilled || !isBreddValid) {
      event.preventDefault()
      messageElement.style.display = 'block'
      messageElement.textContent = !isBreddValid
        ? 'Vänligen korrigera bredden innan du lägger till i varukorgen.'
        : 'Alla fält måste fyllas innan du lägger till i varukorgen.'
    } else {
      messageElement.style.display = 'none'
    }
  })

  const variationIdField = document.querySelector('.variation_id')

  // Observe changes in the variation ID field
  const observer = new MutationObserver(() => {
    updatePrice() // Recalculate price when variation changes
  })

  if (variationIdField) {
    observer.observe(variationIdField, { attributes: true, attributeFilter: ['value'] })
  }

  // Listen for WooCommerce's variation selection
  document.querySelector('form.variations_form')?.addEventListener('change', function (event) {
    const variationId = document.querySelector('.variation_id').value
    if (variationId) {
      updatePrice()
    }
  })
})
